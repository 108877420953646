import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";

// Vue.config.silent = true;

axios.defaults.headers = {
  accessToken: "e6cfe164-00af-4085-8508-57ef5010b394",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};
Vue.use(VueAxios, axios);
import {
  Button,
  Select,
  FormItem,
  Form,
  Option,
  Radio,
  DatePicker,
  Dialog,
  Alert,
  Notification,
  Input,
} from "element-ui";
Vue.use(Button);
Vue.use(Select);
Vue.use(FormItem);
Vue.use(Form);
Vue.use(Option);
Vue.use(DatePicker);
Vue.use(Radio);
Vue.use(Dialog);
Vue.use(Alert);
Vue.use(Input);

Vue.prototype.$notify = Notification;
Vue.config.productionTip = false;
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
