import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/demo",
    component: () => import("../components/ganttDemo.vue"),
  },
  {
    path:'/ganttShortCart',
    component:()=>import('../components/GanttShortCart.vue'),
  },
  {
    path:'/ganttLongCart',
    component:()=>import('../components/GanttLongCart.vue'),
  },
  {
    path: "/403",
    component: () =>
      import(/* webpackChunkName: "403" */ "../views/403View.vue"),
    meta: { title: "403" },
  },
  {
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
