<template>
  <div id="app">
    <transition name="fade">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss">
html,
body {
  margin: 0px;
  padding: 0px;
  /* 溢出隐藏 */
  overflow: hidden;
  font-size: 10px !important;
}
#app {
  width: 100vw;
  height: 100vh;
}
.fade-enter-active,
.fade-leave-avtive {
  transition: opacity 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
